<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";

export default {
  page: {
    title: "Infos Trafic",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Infos trafic",
      tmpCoverFile: null,
      file: null,
      fileModif: null,
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Infos trafic",
          active: true,
        },
      ],
      moyensTransport: ["MOTO", "VOITURE", "TAXI", "BUS"],
      typesDistance: ["COURT", "MOYEN", "LONG"],
      experience: ["R-Débutant", "R-Qualifié", "R-Ambassadeur"],
      codePromoForm: {
        titre: "",
        description: "",
        pathImage: "",
      },
      codePromoFormModif: {
        titre: "",
        description: "",
        pathImage: "",
      },
      codePromoRowInfo: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      codePromo: [],
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "titre", sortable: true },
          { key: "iduser", sortable: true, label: "Utilisateur" },
        { key: "datePub", sortable: true, label: "Date de publication" },
        { key: "description", sortable: true, label: "Description" },
      
        { key: "pathImage", sortable: true, label: "Image" },
        { key: "typeInfos", sortable: true, label: "Type information" },
        { key: "action" },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
      showactive: false,
      erreur: false,
    };
  },
  validations: {},

  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const listCodePromo = await apiRequest(
        "GET",
        "codepromo/find",
        undefined,
        false
      );

      if (listCodePromo && listCodePromo.data) {
  
        const codePromoFormatTable = listCodePromo.data.promo.map((promo) => {
          return {
            id: promo.id,
            pathImage: promo.pathImage,
            titre: promo.titre,
            iduser: promo.iduser,
            datePub: new Date(promo.datePub).toLocaleString("fr-FR"),
            typeInfos: promo.typeInfos,
            description: promo.description,
          };
        });
        this.codePromo = codePromoFormatTable;
      }
    },
    editRow(row) {
      this.showEdit = true;
      this.codePromoFormModif.titre = row.titre;
      this.codePromoFormModif.description = row.description;
      this.codePromoFormModif.id = row.id;
      this.codePromoFormModif.visible = row.visible;
    },
    desactiverRow(row) {
      this.showdisable = true;
      this.codePromoRowInfo = row;
    },
    activerpromo(row) {
      this.showactive = true;
      this.codePromoRowInfo = row;
    },
    deleteRow(row) {
      this.showDelete = true;
      this.codePromoRowInfo = row;
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {

      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

    },

    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    handleFileUploadModif() {
      this.fileModif = this.$refs.files.files[0];
    },

    async submitcodePromoForm() {
      if (
        this.codePromoForm.reduction != 0 &&
        this.codePromoForm.dateLimite != "" &&
        this.codePromoForm.moyensTransport.length > 0 &&
        this.codePromoForm.typesDistance.length > 0 &&
        this.codePromoForm.experience.length > 0
      ) {
        var moyensT = "";
        var typesD = "";
        var exp = "";

        this.codePromoForm.moyensTransport.forEach((element) => {
          moyensT += element + ",";
        });

        this.codePromoForm.typesDistance.forEach((element) => {
          typesD += element + ",";
        });

        this.codePromoForm.experience.forEach((element) => {
          exp += element + ",";
        });


        const returncodePromo = await apiRequest(
          "POST",
          "codepromo",
          {
            code: this.codePromoForm.code,
            reduction: this.codePromoForm.reduction,
            dateLimite: this.codePromoForm.dateLimite,
            birthday: false, 
            moyensTransport: moyensT,
            typesDistance: typesD,
            experience: exp,
          },
          false
        );


        if (returncodePromo && returncodePromo.data) {
     
          this.init();
        }
      } else {

       
         
        this.erreur = true;
      }
      /**/
    },
    async submitcodePromoFormModif() {

      let formData = new FormData();
      formData.append("id", this.codePromoFormModif.id);
      formData.append("titre", this.codePromoFormModif.titre);
      formData.append("sousTitre", this.codePromoFormModif.soustitre);
      formData.append("codePromoLink", this.codePromoFormModif.url_codePromo);
      formData.append("file", this.files);
      formData.append("visible", this.codePromoFormModif.visible);



      const returncodePromo = await apiRequest(
        "PUT",
        "codePromo",
        formData,
        true
      );

      if (returncodePromo && returncodePromo.data) {
     
        this.init();
      }
    },
    async desactivepromo() {
      this.showdisable = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codePromo-off",
        { id: this.codePromoRowInfo.id, visible: false },
        false
      );


      if (returncodePromo && returncodePromo.data) {
        this.init();
      }
    },
    async activepromo() {
      this.showactive = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codePromo-off",
        { id: this.codePromoRowInfo.id, visible: true },
        false
      );


      if (returncodePromo && returncodePromo.data) {
        this.init();
      }
    },
    async deletepromo() {

      this.showDelete = false;
      const returncodePromo = await apiRequest(
        "POST",
        "codepromo/action",
        { id: this.codePromoRowInfo.id, visible: false },
        false
      );


      if (returncodePromo && returncodePromo.data) {

        this.init();
      }
    },
    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <form @submit.prevent="submitcodePromoForm" class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="Code">Titre</label>
                  <input
                    type="text"
                    id="titre"
                    class="form-control"
                    placeholder="titre de la publication"
                    v-model="codePromoForm.titre"
                  />
                </div>
                <div class="form-group">
                  <label for="">Description*</label>
                  <textarea
                    type="number"
                    id=""
                    class="form-control"
                    placeholder="Déscription"
                    v-model="codePromoForm.description"
                  ></textarea>
                </div>
                <div class="form-group ">
                       <label for="">Image</label>
                  <input style="padding-top:0.3rem;"

                    type="file"
                    id="pathImage"
                    ref="pathImage"
                    v-on:change="handleFileMoto"
                    class="form-control"
                  
                  />
                </div>

                <div class="form-group">
                  <button class="btn btn-primary float-right">
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5>Gérer</h5>
            </div>
            <form>
              <b-tabs nav-class="nav-tabs-custom">
                <b-tab title-link-class="p-3">
                  <template v-slot:title>
                    <a class="font-weight-bold active">Liste des informations sur le trafic</a>
                  </template>
                  <div class="row d-flex justify-content-between align-items-center mt-4">
                    <div class="p-3">
                      <div id="tickets-table_length" class="dataTables_length">
                        <label class="d-inline-flex align-items-center">
                          Afficher&nbsp;
                          <b-form-select
                            v-model="perPage"
                            size="sm"
                            :options="pageOptions"
                          ></b-form-select
                          >&nbsp;entrées
                        </label>
                      </div>
                    </div>
                    <!-- Search -->
                    <div class="inputCol p-3">
                    <div class="input">
                      <input
                        type="search"
                        id="serchInput"
                        placeholder="Rechercher..."
                        v-model="filter"
                      />
                    </div>
                  </div>
                    <!-- End search -->
                  </div>
                  <div class="table-responsive">
                    <b-table
                      class="table-centered"
                      :items="codePromo"
                      :fields="fields"
                      responsive="sm"
                      :per-page="perPage"
                      :current-page="currentPage"
                      :sort-by.sync="sortBy"
                      :sort-desc.sync="sortDesc"
                      :filter="filter"
                      :filter-included-fields="filterOn"
                      @filtered="onFiltered"
                    >
                      <template v-slot:cell(action)="row">
                       
                        <a
                          href="javascript:void(0);"
                          class="text-danger"
                          @click="deleteRow(row.item)"
                          v-b-tooltip.hover
                          title="supprimer"
                        >
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </template>
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                      >
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination
                            v-model="currentPage"
                            :total-rows="rows"
                            :per-page="perPage"
                          ></b-pagination>
                        </ul>
                      </div>
                    </div>
                    <!-- End search -->
                  </div>
                </b-tab>
              </b-tabs>
            </form>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="erreur"
      title="Veuillez remplir toutes les options."
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="info" class="ml-3 mr-4" @click="erreur = false"
          >Ok</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ce code promo ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="deletepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>



    <b-modal
      id="modal-backdrop"
      centered
      v-model="showactive"
      title="Voulez-vous vraiment activer bon promo ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showactive = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="activepromo"
          >Oui</b-button
        >
      </div>
    </b-modal>

    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Editer utilisateur"
      title-class="font-18"
      hide-footer
    >
      <form
        enctype="multipart/form-data"
        @submit.prevent="submitcodePromoFormModif"
      >
        <div class="form-group">
          <label for="tite">Titre</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="titre"
            v-model="codePromoFormModif.titre"
          />
        </div>
        <div class="form-group">
          <label for="tite">Sous titre</label>
          />
        </div>
        <div class="form-group">
          <label for="tite">texte</label>
          <input
            type="text"
            id="title"
            class="form-control"
            placeholder="sous titre"
            v-model="codePromoFormModif.soustitre"
          />
        </div>
        <div class="form-group">
          <label for="tite">Lien du bon promo</label>
          <input
            type="url"
            id="title"
            class="form-control"
            placeholder="lien du bon promo"
            v-model="codePromoFormModif.url_codePromo"
          />
        </div>
        <div class="form-group">
          <label for="title">Image</label>
          <input
            type="file"
            id="files"
            ref="files"
            v-on:change="handleFileUploadModif"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Modifier</button>
          placeholder="titre" />
        </div>
        <div class="form-group">
          <label for="tite">image</label>
          <input type="file" id="title" class="form-control" />
        </div>
        <div class="form-group">
          <button class="btn btn-primary float-right">Ajouter</button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>
<style scoped>
.imageCard{
  display: flex;
  align-items: center;
  flex-direction: column;

}




thead {
  background: #3AAA35;
}thead div{
  color:#fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}
.input input:focus {
  border: 3px solid #3aaa35;
}
.inputCol {
  position: relative;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}
</style>